<template>
  <div>
    <b-container fluid style="padding:0">
        <b-row class="borderColor" style="background: #fff">
                <div class="flex">
                    <div class="infoWidth" v-for="(i,index) in fakeArr" :key="index" style="font-weight:700">
                        <div class="colorBox"></div>
                        <div class="infoBackground" @click="change(index)">
                            <div style="display:flex">
                                <div class="insideWidth">id</div>
                                <div class="insideWidth">I/F</div>
                            </div>
                            <div style="display:flex">
                                <div class="insideWidth">Virtual PORT1</div>
                                <div class="insideWidth">連線: OK</div>
                                <div class="insideWidth">回應時間:6138ms</div>
                            </div>
                        </div>
                        <table v-show="i.show">
                            <tr>
                                <th>name</th>
                                <th>value</th>
                            </tr>
                            <tr v-for="i in 10" :key='i'>
                                <td width="30%">電流</td>
                                <td width="70%">2.11111545478</td>
                            </tr>
                        </table>
                    </div>
                </div>
        </b-row>
    </b-container>
  </div>
</template>

<script>
// import cookies from 'vue-cookies'
// import axios from 'axios'
export default {
  components: {
  },
  data () {
    return {
      tableShow: false,
      fakeArr: [
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    change (index) {
      this.fakeArr[index].show = !this.fakeArr[index].show
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/RealTime.scss';
</style>
<style lang="scss" scoped>
.flex {
  display:flex;
  align-items: flex-start;
  padding:10px;
  flex-direction: row;
  flex-flow:row wrap;
}
    .infoBackground {
        cursor: pointer;
        position: relative;
        background: rgb(211, 231, 211);
        padding: 10px;
        margin: 0px 12px;
        box-shadow: 5px 6px 2px green;
        // border: 1px solid #fff;
    }
    .infoWidth{
        position: relative;
        flex:0 0 32.4%;
        margin: 6px;
        text-align: center;
        min-width: 350px;
        // border: 2px solid rgb(185, 168, 168);
        .colorBox{
          position: absolute;
          background-color: #cfeaff;
          width: 100%;
          height: 50PX;
          top: 25px;
          border-radius: 5px;
          // border: solid 1px#fff;
        }
    }
    .insideWidth{
        width: 100%;
    }
    table {
  width: 100% !important;
}

table tr{
  /* background-color: #f8f8f8; */
  background-color: #cfeaff;
}
table th {
  padding: 10px 30px;
  /* background-color: #c5c3c3; */
  border-bottom: solid 1px#fff;
  border-top: none;
  // background-color: #7cb5ec;
  font-size: 18px;
  background-color:#cfeaff;
  text-align: left;
}

table td {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 30px;
  border-bottom: 1px solid #fff;
  text-align: left;
  max-width: 340px;
  min-width: 70px;
}
</style>
